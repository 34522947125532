// Here you can add other styles

// https://coreui.io/docs/getting-started/theming/


// tighten up spacing
$spacer: 0.8rem;
$font-size-base: .84rem;
// $line-height-base:  1.3;


// $font-weight-light:    200;
// $font-weight-normal:   300;
// $font-weight-bold:     600;

$sidebar-width:     220px;



// Branding

// used https://www.w3schools.com/colors/colors_picker.asp
// against brand colours of 
// [Colors]
// #26252C : dark
// #A6392C : Orange
// #FFA502 : Yellow


$gray-base:  #26252C;
$gray-100:   #f1f1f3;
$gray-200:   #d6d5dc;
$gray-300:   #c9c7d1;
$gray-400:   #bbbac5;
$gray-500:   #9390a2;
$gray-600:   #858297;
$gray-700:   #77748b;
$gray-800:   #605d6f;
$gray-900:   #484653;



$primary-base:  #ffa502;
$primary-100:   #fff6e6;
$primary-200:   #ffedcc;
$primary-300:   #ffe4b3;
$primary-400:   #ffdb99;
$primary-500:   #ffd280;
$primary-600:   #ffc966;
$primary-700:   #ffc14d;
$primary-800:   #ffb833;
$primary-900:   #ffaf1a;

$primary-dark:  #996300;
$primary:       #ffa502;
$primary-50:    #ffd280;
$primary-25:    #ffedcc;


$link-color: $primary-dark;