// If you want to override variables do it here
@import "variables";

// If you want to add something do it here
@import "custom";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";


@import '~leaflet/dist/leaflet.css';


// react sortable tree customisation
.rst__rowTitleWithSubtitle {
    height: 100% !important
}

.rst__rowSubtitle {
    font-size: 100% !important
}

.rst__rowTitle {
    font-weight: normal !important
}


// react timeline scribble customization
.css-vcfpfv, .css-692fgp {
    background-color: $gray-700 !important
}


.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--in-range,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: $primary-base !important;
}